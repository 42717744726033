import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import loadable from "@loadable/component";
import * as serviceWorker from "serviceWorker";

const DashboardRoute = loadable(() => import("domain/DashboardRoute"));
const PublicAudioPlayer = loadable(() => import("domain/PublicAudioPlayer"));
const SignInPage = loadable(() => import("domain/SignInPage"));
const ForgotPasswordPage = loadable(() => import("domain/ForgotPasswordPage"));

function App() {
  useEffect(handleSwRegistration, []);

  return (
    <div className="full-height" data-testid="app-container">
      <Router>
        <Switch>
          <Route path="/dashboard">
            <DashboardRoute />
          </Route>
          <Route path={["/forgot-password"]}>
            <ForgotPasswordPage />
          </Route>
          <Route path="/public/audio/:token">
            <PublicAudioPlayer />
          </Route>
          <Route path={["/", "/signin"]}>
            <SignInPage />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;

const handleSwRegistration = () => {
  // unregister SW because we have problems with devebs
  serviceWorker.unregister();
  // if (process.env.NODE_ENV === "production") {
  //   serviceWorker.register({
  //     onSuccess: () =>
  //       notification.success({
  //         message: "Key Way Group installed",
  //         description:
  //           "This web application has been installed for offline use."
  //       }),
  //     onUpdate: () =>
  //       notification.info({
  //         message: "Key Way Group update available",
  //         description:
  //           "A new version of this application is available. Please close all tabs for this page to update.",
  //         duration: 0
  //       })
  //   });
  // } else {
  //   serviceWorker.unregister();
  // }
};
